import React, { useCallback, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { AlbumImages } from '../../interfaces/album-images';
import { ImageService } from '../../services';
import './imagedialog.scss';

interface ImageDialogProps {
  albumImages?: AlbumImages;
  index: number;
  setIndex: any;
  album: string;
  open: boolean;
  handleClose: any;
}

const ImageDialog: React.FC<ImageDialogProps> = ({ albumImages, index, setIndex, album, open, handleClose }) => {
  const back = () => {
    if (index - 1 > 0) {
      setIndex(index - 1);
    }
  };

  const forward = () => {
    if (albumImages?.images !== undefined && index + 1 < albumImages.images.length) {
      setIndex(index + 1);
    }
  };

  const keyScroll = useCallback(
    (e) => {
      if (e.keyCode === 39) {
        forward();
      }
      if (e.keyCode === 37) {
        back();
      }
    },
    [index],
  );

  useEffect(() => {
    document.addEventListener('keydown', keyScroll, false);
    return () => {
      document.removeEventListener('keydown', keyScroll, false);
    };
  });

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='responsive-dialog-title' maxWidth='lg'>
      <DialogContent>
        <div className='back-button' onClick={handleClose}>
          <DoubleArrowIcon />
          Nazad
        </div>
        <div className='album-dialog'>
          <ArrowBackIosIcon className='buttons-album-image' onClick={back} />
          <img src={ImageService.getAPIAddress(album, albumImages?.images[index])} alt='Loading...' />
          <ArrowForwardIosIcon className='buttons-album-image' onClick={() => forward()} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ImageDialog;
