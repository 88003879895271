import moment from 'moment';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { ImageService } from '../../../services';
// @ts-ignore
import './listelement.scss';

interface ListElementProps {
  id: number;
  title: string;
  date: Date;
  content: string;
}

const ListElement: React.FC<ListElementProps> = ({ id, title, date, content }) => {
  const history = useHistory();
  const openDetailes = () => {
    history.push(`/aktualnost/${id}`);
  };

  return (
    <div className='list-element'>
      <div className='list-picture'>
        <img src={ImageService.getAPIAddress(`news${id}`, '1.jpg')} alt='problem with loading' />
      </div>
      <div className='content-wrapper'>
        <div className='element-date'>{moment(date).format('DD.MM.yyyy HH:mm')}</div>
        <div className='list-title'>
          <a href={`aktualnost/${id}`}>{title}</a>
        </div>
        <p className='list-content'>{content}</p>
        <div className='more-button'>
          <button type='button' className='list-button' onClick={openDetailes}>
            Pročitaj više
          </button>
        </div>
      </div>
    </div>
  );
};

export default ListElement;
