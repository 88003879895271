import * as React from 'react';
import { useHistory } from 'react-router-dom';
import './album.scss';
import { Helmet } from 'react-helmet';
import { AlbumModel } from '../../../interfaces/album';
import { ImageService } from '../../../services';

interface ImageBackground {
  album: AlbumModel;
}

const Album: React.FC<ImageBackground> = ({ album }) => {
  const history = useHistory();
  const openImages = () => {
    history.push(`album/${album.id}`);
  };

  return (
    <div className='album-cover' onClick={openImages}>
      <Helmet>
        <title> Arhiva | HKC &quot;Bunjevačko kolo&quot; </title>
        <meta property='og:title' content='Fotografije i video snimci | HKC "Bunjevačko kolo"' />
        <meta property='og:url' content='http://hkcbunjevackokolo.rs/album/arhiva' />
      </Helmet>
      <img src={ImageService.getAPIAddress(album.id, '1.jpg')} alt='Error loading' />
      <div className='album-content'>
        <div>{album.name}</div>
      </div>
    </div>
  );
};

export default Album;
