import { Http } from '.';
import config from '../config';
import { YTVideo } from '../interfaces/video';

interface VideoService {
  getAll(): Promise<YTVideo[]>;
}

class VideoServiceImpl implements VideoService {
  getAll = (): Promise<YTVideo[]> => Http.get(`${config.API_URLS.videos}`);
}

export default new VideoServiceImpl();
