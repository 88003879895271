import * as React from 'react';
import './video.scss';
import YouTubePlayer from 'react-player/youtube';

interface VideoProps {
  title: string;
  content: string;
  url: string;
}

const Video: React.FC<VideoProps> = ({ title, content, url }) => (
  <div className='videos-container'>
    <div className='title-content'>
      <div className='videos-title'>{title}</div>
      <div className='videos-content'>{content}</div>
    </div>
    <div className='video-player'>
      <YouTubePlayer className='html-player' url={url} width='430px' height='240px' controls />
    </div>
  </div>
);

export default Video;
