import * as React from 'react';
import './tileContent.scss';

interface TileContentProps {
  title: string;
  text: string;
}

const TileContent: React.FC<TileContentProps> = ({ title, text }) => (
  <div className='wrapper'>
    <div className='content-title'>{title}</div>
    <div className='content-text'>{text}</div>
  </div>
);

export default TileContent;
