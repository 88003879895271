import React from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import './about.scss';

const AboutPage: React.FC = () => {
  const history = useHistory();
  const openDetails = (type) => history.push(`o_nama/${type}`);
  return (
    <div className='app-div'>
      <Header />

      <div className='main-page-about'>
        <div className='center-div'>
          <div className='about-div left-div' onClick={() => openDetails('povijest')}>
            <div className='about-content'>
              <div className='about-title'>Povijest</div>
              <div className='about-text'>
                Osnovano na veoma posjećenoj skupštini 18. siječnja 1970., HKUD Bunjevačko kolo je opravdalo svoje postojanje već u prvim
                mjesecima svoga djelovanja. Kao nijedno društvo prije, okupilo je gotovo sve viđenije hrvatske intelektualce različitih
                profila, ideoloških orijentacija i političke prošlosti. Kao takvo, moglo je prirediti više manifestacija i priredaba, koje
                po značenju nisu zaostajale za onim koje je upriličio Organizacijski odbor za Dužijancu. Međutim, točan slijed aktivnosti
                HKUD-a Bunjevačko kolo u početnom razdoblju njegovog djelovanja teško je pratiti, uslijed raspršenosti i fragmentiranosti
                relevantnih izvora. Sâma arhiva Bunjevačkog kola oskudijeva dokumentima iz razdoblja 1970. – 1972. Dokumenti o radu
                pojedinih sekcija Društva (istraživačka i književna sekcija) nalaze se u zaostavštini Bele Gabrića (Bunjevačko-šokačka
                knjižnica Ivan Kujundžić). Ovakvo stanje je posljedica prije svega neprilika koje su 1973. snašle HKUD Bunjevačko kolo i
                uostalom čitavu hrvatsku intelektualnu elitu.
              </div>
            </div>
          </div>
          <div className='about-div right-div' onClick={() => openDetails('vodstvo')}>
            <div className='about-content'>
              <div className='about-title'>Uprava</div>
              <div className='about-text'>
                Upravni odbor (UO) je sačinjen od 11 članova koje bira skupština Centra i mandat im traje četiri godine.
                <br />
                <br />
                Predsjednik UO:
                <br />
                Lazar Cvijin
                <br />
                <br />
                Članovi:
                <br />
                Pavle Kujundžić
                <br />
                Denis Lipozenčić
                <br />
                Dajana Šimić
                <br />
                Tanja Dulić
                <br />
                Željka Vukov
                <br />
                Ivica Tumbas
                <br />
                Marinko Katić
                <br />
                Antun Kozma
                <br />
                Svetislav Milanković
                <br />
                Ivica Ivanković Radak
                <br />
                <br />
                Izvršni odbor:
                <br />
                Predsjednik IO:
                <br />
                Marin Piuković
                <br />
                <br />
                Članovi:
                <br />
                Marin Jaramazović
                <br />
                Senka Horvat
                <br />
                Vlatko Vidaković
                <br />
                Josipa Križanović
                <br />
                Nevena Mlinko
                <br />
                Marjan Kiš
                <br />
                <br />
                Nadzorni odbor:
                <br />
                <br />
                Predsjednik NO:
                <br />
                Miroslav Doko
                <br />
                <br />
                Članovi:
                <br />
                Mirko Ostrogonac
                <br />
                Suzana Vukov
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutPage;
