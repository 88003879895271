import Http from './http';
import config from '../config';

interface EmailRequest {
  name: string;
  email: string;
  text: string;
}

interface ContactService {
  post(data: EmailRequest): Promise<string>;
}

class ContactServiceImpl implements ContactService {
  post = (data: EmailRequest): Promise<string> => Http.post(config.API_URLS.emails, data);
}

export default new ContactServiceImpl();
