import React, { useEffect, useState } from 'react';
import { AlbumModel } from '../../../interfaces/album';
import { ImageService } from '../../../services';
import Album from '../album';
import './images.scss';

const Images: React.FC = () => {
  const [albums, setAlbums] = useState<AlbumModel[]>([]);

  useEffect(() => {
    const getAlbums = async () => {
      const allAlbums = await ImageService.getAll();
      setAlbums(allAlbums);
    };
    getAlbums();
  }, []);
  return (
    <div className='images-container'>
      {albums?.map((album) => (
        <Album album={album} />
      ))}
    </div>
  );
};

export default Images;
