import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Helmet } from 'react-helmet';
import Header from '../header';
import Footer from '../footer';
import Tile from '../tile';
import TileContent from '../tileContent';
import { Tradition } from '../../interfaces/tradition';
import { ImageService, TraditionService } from '../../services';

const EventsPage: React.FC = () => {
  const [traditions, setTraditions] = useState<Tradition[]>([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const getTraditions = async () => {
      const allTraditions = await TraditionService.getAll();
      setTraditions(allTraditions);
      setLoading(false);
    };
    getTraditions();
  }, []);

  const showEvent = (id: number) => {
    history.push(`manifestacija/${id}`);
  };
  return (
    <div className='app-div'>
      <Helmet>
        <title> Manifestacije | HKC &quot;Bunjevačko kolo&quot; </title>
        <meta property='og:title' content='Manifestacije' />
        <meta property='og:url' content='http://hkcbunjevackokolo.rs/manifestacije' />
        <meta property='og:image' content={ImageService.getTraditionByFileName(1, 'ulaz.jpg')} />
      </Helmet>
      {loading && <LinearProgress color='secondary' />}
      <Header />
      <div className='departments-wrapper'>
        {traditions.map((tradition, index) => (
          <div className='department-wrapper' onClick={() => showEvent(tradition.id)}>
            {index % 2 === 0 ? (
              <Tile
                leftElement={<TileContent title={tradition.name} text={tradition.description} />}
                rightElement={
                  <img
                    className='department-image'
                    width='100%'
                    src={ImageService.getTraditionByFileName(tradition.id, tradition.inputImage)}
                    alt='Error loading'
                  />
                }
              />
            ) : (
              <Tile
                rightElement={<TileContent title={tradition.name} text={tradition.description} />}
                leftElement={
                  <img
                    className='department-image'
                    width='100%'
                    src={ImageService.getTraditionByFileName(tradition.id, tradition.inputImage)}
                    alt='Error loading'
                  />
                }
              />
            )}
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default EventsPage;
