import * as React from 'react';
import { useParams } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import Header from '../../header';
import Footer from '../../footer';
import './album-images.scss';
import { AlbumImages } from '../../../interfaces/album-images';
import { ImageService } from '../../../services';
import ImageDialog from '../../image-dialog/imagedialog';

const AlbumImagesPage: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [albumImages, setAlbumImages] = React.useState<AlbumImages>();
  const { album } = useParams<{ album: string }>();
  const [open, setOpen] = React.useState(false);
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const getImages = async () => {
      const allImages = await ImageService.getAlbumImages(album);
      setAlbumImages(allImages);
      setLoading(false);
    };
    getImages();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const openImage = (index) => {
    setIndex(index);
    setOpen(true);
  };

  return (
    <div className='app-div'>
      {loading && <LinearProgress color='secondary' />}
      <Header />
      <a className='back-button' href='/arhiva'>
        <DoubleArrowIcon />
        Nazad
      </a>
      <div className='images-title'> {albumImages?.name} </div>
      <div className='images-container'>
        {albumImages?.images?.map((imgsrc, index) => (
          <div className='images-wrapper' onClick={() => openImage(index)}>
            <img src={ImageService.getAPIAddress(album, imgsrc)} alt='Error loading' />
          </div>
        ))}
        <ImageDialog albumImages={albumImages} index={index} setIndex={setIndex} album={album} open={open} handleClose={handleClose} />
      </div>
      <Footer />
    </div>
  );
};

export default AlbumImagesPage;
