import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LinearProgress } from '@material-ui/core';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { Helmet } from 'react-helmet';
import { News } from '../../../interfaces/news';
import { NewsService } from '../../../services';
import Header from '../../header';
import Footer from '../../footer';
import './newsdetails.scss';
import CustomizedDialogs from '../newsimage';

const NewsDetailesPage: React.FC = () => {
  const [news, setNews] = useState<News | null>(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams<{ id: string }>();
  const [imageDialog, setImageDialog] = useState(false);

  useEffect(() => {
    const getNews = async () => {
      const selectedNews = await NewsService.getById(Number(id));
      setNews(selectedNews);
      setLoading(false);
    };
    getNews();
  }, []);

  const handleClose = () => {
    console.log('triggered... ');
    setImageDialog(false);
  };

  const handleOpen = () => {
    console.log('opentr');
    setImageDialog(true);
  };

  return (
    <div className='app-div'>
      <Helmet>
        <title> {`${news?.name} | HKC "Bunjevačko kolo"`} </title>
        <meta property='og:title' content={`${news?.name}`} />
        <meta property='og:url' content={`http://hkcbunjevackokolo.rs/aktualnost/${news?.id}`} />
        <meta property='og:image' content={`${NewsService.getImage(news?.id)}`} />
      </Helmet>
      {loading && <LinearProgress color='secondary' />}
      <Header />
      <a className='back-button' href='/aktualnosti'>
        <DoubleArrowIcon />
        Nazad
      </a>
      <div>
        <div className='dep-container'>
          <p className='dep-title'>{news?.name}</p>
          <div className='images-container' onClick={() => handleOpen()}>
            <img src={NewsService.getImage(news?.id)} alt='Error loading' />
          </div>
          <p className='dep-content'>{news?.description}</p>
          <CustomizedDialogs id={news?.id} open={imageDialog} handleClose={handleClose} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NewsDetailesPage;
