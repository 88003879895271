export default {
  HOME: '/',
  ABOUT: '/o_nama',
  ABOUT_DETAILS: '/o_nama/:type',
  ARCHIVE: '/arhiva',
  CONTACT: '/kontakt',
  DEPARTMENTS: '/odjeli',
  EVENTS: '/manifestacije',
  EVENT: '/manifestacija/:id',
  NEWS: '/aktualnosti',
  NEWS_DETAILES: '/aktualnost/:id',
  DEPARTMENT: '/odjel/:id',
  IMAGES: '/album/:album',
};
