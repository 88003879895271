import React from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import { ImageService } from '../../../services';
import './dialog.css';

interface CustomizedDialogProps {
  id: number | undefined;
  open: boolean;
  handleClose: () => void;
}

const NewsImageDialog: React.FC<CustomizedDialogProps> = ({ id, open, handleClose }) =>
  open ? (
    <div className='dialog-wrapper'>
      <div className='dialog-content'>
        <div className='dialog-back-button' onClick={handleClose}>
          <CancelIcon className='dialog-close-icon' />
        </div>
        <img src={ImageService.getAPIAddress(`news${id}`, '1.jpg')} alt='loading' height='100%' />
      </div>
    </div>
  ) : null;
export default NewsImageDialog;
