import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import Navigation from './navigation';
import './App.scss';

const App: React.FC = () => {
  const theme = createMuiTheme({
    palette: {
      secondary: {
        main: '#9b5542',
      },
    },
  });
  const desc =
    'Hrvatski kulturni centar „Bunjevačko kolo“ je udruga, slobodno i dobrovoljno udruženih građana u oblasti kulture, umjetnosti i znanosti osnovan 18.01.1970.';
  return (
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <Helmet>
          <meta charSet='utf-8' />
          <title> HKC &quot;Bunjevačko kolo&quot; </title>
          <meta name='description' content={desc} />
          <meta property='og:title' content='HKC "Bunjevačko kolo"' />
          <meta property='og:url' content='http://hkcbunjevackokolo.rs/' />
          <meta property='og:image' content='http://hkcbunjevackokolo.rs/logo.jpg' />
          <meta property='og:description' content={desc} />
        </Helmet>
        <Navigation />
      </MuiThemeProvider>
    </BrowserRouter>
  );
};

export default App;
