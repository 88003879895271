import * as React from 'react';
import './tiles.scss';

interface TileProps {
  leftElement: any;
  rightElement: any;
}
const Tile: React.FC<TileProps> = ({ leftElement, rightElement }) => (
  <div>
    <div className='tile-element tile-element-image'>{leftElement}</div>
    <div className='tile-element tile-element-text'>{rightElement}</div>
  </div>
);

export default Tile;
