const API_URLS = {
  news: 'news',
  sections: 'sections/',
  traditions: 'traditions/',
  emails: 'emails',
  albums: 'images/albums',
  imgtraditions: 'images/albums/traditions/',
  imgsections: 'images/albums/sections/',
  videos: 'youtube',
};

interface Config {
  [name: string]: any;
}

const CONFIG: Config = {
  development: {
    BASE_URL: 'http://localhost:8099',
  },
  production: {
    BASE_URL: 'http://45.80.135.145:8099',
  },
};

export default {
  ...CONFIG[process.env.REACT_APP_ENV || 'development'],
  API_URLS,
};
