import APP_PATHS from '../../paths';
import HomePage from '../../../components/home';
import ArchivePage from '../../../components/archive';
import ContactPage from '../../../components/contact';
import DepartmentsPage from '../../../components/departments';
import NewsPage from '../../../components/news';
import AboutPage from '../../../components/about';
import DepartmentPage from '../../../components/departments/department';
import ImagesPage from '../../../components/archive/album-images';
import NewsDetailesPage from '../../../components/news/news-detailes';
import EventsPage from '../../../components/events';
import AboutDetailsPage from '../../../components/about/about-details';
import EventPage from '../../../components/events/event';

export default [
  {
    component: HomePage,
    exact: true,
    path: APP_PATHS.HOME,
  },
  {
    component: AboutPage,
    exact: true,
    path: APP_PATHS.ABOUT,
  },
  {
    component: AboutDetailsPage,
    exact: true,
    path: APP_PATHS.ABOUT_DETAILS,
  },
  {
    component: ArchivePage,
    exact: true,
    path: APP_PATHS.ARCHIVE,
  },
  {
    component: ContactPage,
    exact: true,
    path: APP_PATHS.CONTACT,
  },
  {
    component: DepartmentPage,
    exact: true,
    path: APP_PATHS.DEPARTMENT,
  },
  {
    component: DepartmentsPage,
    exact: true,
    path: APP_PATHS.DEPARTMENTS,
  },
  {
    component: EventsPage,
    exact: true,
    path: APP_PATHS.EVENTS,
  },
  {
    component: EventPage,
    exact: true,
    path: APP_PATHS.EVENT,
  },
  {
    component: NewsPage,
    exact: true,
    path: APP_PATHS.NEWS,
  },
  {
    component: NewsDetailesPage,
    exact: true,
    path: APP_PATHS.NEWS_DETAILES,
  },
  {
    component: ImagesPage,
    exact: true,
    path: APP_PATHS.IMAGES,
  },
];
