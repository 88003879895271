import Http from './http';
import config from '../config';
import { Tradition } from '../interfaces/tradition';

interface TraditionService {
  getAll(): Promise<Tradition[]>;
  getById(id: number): Promise<Tradition>;
}

class TraditionServiceImpl implements TraditionService {
  getAll = (): Promise<Tradition[]> => Http.get(config.API_URLS.traditions);

  getById = (id: number): Promise<Tradition> => Http.get(config.API_URLS.traditions + id);
}

export default new TraditionServiceImpl();
