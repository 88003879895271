import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Helmet } from 'react-helmet';
import Footer from '../../footer';
import Header from '../../header';
import './department.scss';
import { Section } from '../../../interfaces/section';
import { SectionService } from '../../../services';
import imageService from '../../../services/image.service';
import ImageDialogST from '../../image-dialog/imgsectionstraditions';

const DepartmentPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [section, setSection] = useState<Section | null>(null);
  const [loading, setLoading] = useState(true);
  const [index, setIndex] = useState(0);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const getSection = async () => {
      try {
        const selectedSection = await SectionService.getById(Number(id));
        setSection(selectedSection);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    getSection();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const openImage = (index) => {
    setIndex(index);
    setOpen(true);
  };

  return (
    <div className='app-div'>
      <Helmet>
        <title> {`${section?.name} | HKC "Bunjevačko kolo"`} </title>
        <meta property='og:title' content={`${section?.name}`} />
        <meta property='og:url' content={`http://hkcbunjevackokolo.rs/odjel/${section?.id}`} />
        <meta property='og:image' content={`${imageService.getSectionByFileName(section?.id, '1.jpg')}`} />
      </Helmet>
      {loading && <LinearProgress color='secondary' />}
      <Header />
      <a className='back-button' href='/odjeli'>
        <DoubleArrowIcon />
        Nazad
      </a>
      <div>
        <div className='dep-container'>
          <p className='dep-title'>{section?.name}</p>
          <p className='dep-content'>{section?.description}</p>
          <div className='images-container'>
            {section?.images?.map((p, index) => (
              <span className='image-click-hover' onClick={() => openImage(index)}>
                <img src={imageService.getSectionByFileName(section.id, p)} alt='Error loading' />
              </span>
            ))}
          </div>
        </div>
      </div>
      <ImageDialogST
        photos={section?.images}
        index={index}
        setIndex={setIndex}
        album={section?.id}
        open={open}
        handleClose={handleClose}
        section
      />
      <Footer />
    </div>
  );
};

export default DepartmentPage;
