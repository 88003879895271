import React, { useState } from 'react';
import EmailIcon from '@material-ui/icons/Email';
import PersonIcon from '@material-ui/icons/Person';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import PhoneIcon from '@material-ui/icons/Phone';
import Alert from '@material-ui/lab/Alert';
import { Helmet } from 'react-helmet';
import Header from '../header';
import Footer from '../footer';
import './contact.scss';
import { ContactService } from '../../services';

const ContactPage: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [content, setContent] = useState('');
  const [showBEError, setShowBEError] = useState(false);
  const [showError, setShowError] = useState(false);
  const [sentMail, setSentMail] = useState(false);

  const submitForm = async (event) => {
    event.preventDefault();
    try {
      if (name && email && content) {
        setShowError(false);
        setShowBEError(false);
        await ContactService.post({ name, email, text: content });
        setSentMail(true);
      } else {
        setSentMail(false);
        setShowError(true);
        setShowBEError(false);
      }
    } catch (err) {
      setShowError(false);
      setSentMail(false);
      setShowBEError(true);
    }
  };
  return (
    <div className='app-div'>
      <Helmet>
        <title> Kontakt | HKC &quot;Bunjevačko kolo&quot; </title>
        <meta property='og:title' content='Kontakt | HKC "Bunjevačko kolo"' />
        <meta property='og:url' content='http://hkcbunjevackokolo.rs/kontakt' />
      </Helmet>
      <Header />
      <div className='contact-container'>
        <div className='contact-form'>
          <div className='form-holder'>
            {showError && <Alert severity='error'>Sva polja su obavezna</Alert>}
            {showBEError && <Alert severity='error'>Došlo je do greške</Alert>}
            {sentMail && <Alert severity='success'>Mejl je poslat </Alert>}
            <form onSubmit={submitForm}>
              <div className='contact-fields'>
                <PersonIcon className='input-icon' />
                <input placeholder='Ime' type='text' name='name' onChange={(e) => setName(e.target.value)} />
              </div>
              <div className='contact-fields'>
                <EmailIcon className='input-icon' />
                <input placeholder='Email' type='text' name='email' onChange={(e) => setEmail(e.target.value)} />
              </div>
              <textarea
                className='input-field'
                placeholder='Unesite Vaše pitanje'
                name='name'
                onChange={(e) => setContent(e.target.value)}
              />
              <button className='submit-button' type='submit'>
                Pošalji
              </button>
            </form>
          </div>
        </div>
        <div className='contact-info'>
          <p className='contact-title'>HKC &quot;Bunjevačko kolo&quot;</p>
          <div className='contact-info-icons'>
            <LocationOnIcon className='contact-icon' />
            <p className='contact-icon'>Preradovićeva 4, 24000 Subotica</p>
          </div>
          <div className='contact-info-icons'>
            <PhoneIcon className='contact-icon' />
            <p className='contact-icon'>+381 (0)24 556-898</p>
          </div>
          <div className='contact-info-icons'>
            <EmailIcon className='contact-icon' />
            <p className='contact-icon'>hkcbunjevackokolosubotica@yahoo.com</p>
          </div>
          <div className='contact-info-icons'>
            <a href='https://www.facebook.com/hkcbunjevackokolosubotica'>
              <FacebookIcon className='contact-icon colored-icons' />
            </a>
            <a href='https://www.instagram.com/hkc_bunjevackokolo'>
              <InstagramIcon className='contact-icon colored-icons' />
            </a>
            <a href='https://www.youtube.com/channel/UCMtRLs58SLFfIb_UKpkwzAQ'>
              <YouTubeIcon className='contact-icon colored-icons' />
            </a>
          </div>
        </div>
      </div>
      <div className='contact-footer'>
        <Footer />
      </div>
    </div>
  );
};

export default ContactPage;
