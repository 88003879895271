import * as React from 'react';
import { useHistory } from 'react-router-dom';
import 'react-slideshow-image/dist/styles.css';
import { Slide } from 'react-slideshow-image';
import folklorImage from '../../images/folklornislajder.jpg';
import dramskiImage from '../../images/dramskislajder.jpg';
import likovniImage from '../../images/likovni-ulaz.jpg';
import knjizevniImage from '../../images/ktkslajder.jpg';
import orkestarImage from '../../images/orkestarslajder.jpg';
import preljeImage from '../../images/preljeslajder.jpg';
import './slider.css';

const Slider: React.FC = () => {
  const images = [folklorImage, dramskiImage, likovniImage, knjizevniImage, orkestarImage, preljeImage];
  const history = useHistory();

  const openDepartment = (id: number) => {
    history.push(`/odjel/${id}`);
  };

  return (
    <div className='slide-container'>
      <Slide>
        <div className='each-slide' onClick={() => openDepartment(1)}>
          <div className='img-text' style={{ backgroundImage: `url(${images[0]})` }}>
            <div className='transparent-tile'>
              <div>
                <h2>Folklorni odjel</h2>
                <p className='slider-text'>
                  Grupa mladih krajem 1969. godine okupila se sa željom da kroz glazbu, ples i pjesmu sačuvaju svoju tradiciju i dalje je
                  njeguju.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='each-slide' onClick={() => openDepartment(4)}>
          <div className='img-text' style={{ backgroundImage: `url(${images[1]})` }}>
            <div className='transparent-tile'>
              <div>
                <h2>Dramski odjel</h2>
                <p className='slider-text'>Dramski odjel HKC &ldquo;Bunjevačko kolo&ldquo; počeo je s radom 2015. g.</p>
              </div>
            </div>
          </div>
        </div>
        <div className='each-slide' onClick={() => openDepartment(5)}>
          <div className='img-text' style={{ backgroundImage: `url(${images[2]})` }}>
            <div className='transparent-tile'>
              <div>
                <h2>Likovni odjel</h2>
                <p className='slider-text'>
                  Ubrzo nakon osnutka društva,osnovan je i likovni odjel,kojeg je tada vodio likovni pedagog Stipan Šabić.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='each-slide' onClick={() => openDepartment(2)}>
          <div className='img-text' style={{ backgroundImage: `url(${images[3]})` }}>
            <div className='transparent-tile'>
              <div>
                <h2>Književno teatarski kutak</h2>
                <p className='slider-text'>Književno-teatarski kružok HKC &ldquo;Bunjevačko kolo&ldquo; oformljen je 2019. godine.</p>
              </div>
            </div>
          </div>
        </div>
        <div className='each-slide' onClick={() => openDepartment(6)}>
          <div className='img-text' style={{ backgroundImage: `url(${images[4]})` }}>
            <div className='transparent-tile'>
              <div>
                <h2>Orkestar</h2>
                <p className='slider-text'>
                  Dolaskom Lazara Malagurskog sa nekolicinom tamburaša u prvim danima osnutka društva počinje rad orkestra Centra.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='each-slide' onClick={() => openDepartment(3)}>
          <div className='img-text' style={{ backgroundImage: `url(${images[5]})` }}>
            <div className='transparent-tile'>
              <div>
                <h2>Pjevačka skupina &ldquo;Prelje&ldquo;</h2>
                <p className='slider-text'>
                  Članice folklornog odjela, još od 2017. godine radile su na usavršavanju koreografskih pjesama, kako za nastupe, tako i za
                  naše koncerte.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Slide>
    </div>
  );
};

export default Slider;
