import * as React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Header from '../header';
import Footer from '../footer';
import './archive.scss';
import Images from './images';
import Video from './video';
import VideoService from '../../services/VideoService';
import { YTVideo } from '../../interfaces/video';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const ArchivePage: React.FC = () => {
  const [videos, setVideos] = React.useState<YTVideo[]>([]);

  React.useEffect(() => {
    const getVideos = async () => {
      const allVideos = await VideoService.getAll();
      setVideos(allVideos);
    };
    getVideos();
  }, []);

  const [page, setPage] = React.useState(1);
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };
  return (
    <div>
      <Header />
      <div className={classes.root}>
        <AppBar position='static' color='default'>
          <Tabs value={value} onChange={handleChange} indicatorColor='secondary' textColor='secondary'>
            <Tab label='Fotografije' {...a11yProps(0)} />
            <Tab label='Video' {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
          <TabPanel value={value} index={0}>
            <Images />
          </TabPanel>
          <div className='app-div-video'>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <div className='videos'>
                {videos.slice(page * 10 - 10, page * 10).map((video) => (
                  <div className='video'>
                    <Video title={video.title} content={video.description} url={video.url} />
                  </div>
                ))}
              </div>
            </TabPanel>
            <Pagination count={Math.ceil(videos.length / 10)} shape='rounded' size='large' onChange={(event, page) => setPage(page)} />
          </div>
        </SwipeableViews>
      </div>
      <Footer />
    </div>
  );
};

export default ArchivePage;
