import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import InfoIcon from '@material-ui/icons/Info';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExtensionIcon from '@material-ui/icons/Extension';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import ArchiveIcon from '@material-ui/icons/Archive';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import clsx from 'clsx';
import './header.scss';
import logo from '../../images/logohkc.jpg';
import { APP_PATHS } from '../../navigation';
import PATHS from '../../navigation/paths';
import newsImage from '../../images/menu_icons/aktualnosti.png';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

const Header: React.FC = () => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState('');
  useEffect(() => {
    setCurrentPath(location.pathname);
  });

  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const getIcon = (index) => {
    switch (index) {
      case 0:
        return <InfoIcon color='secondary' />;
      case 1:
        return <img src={newsImage} alt='' />;
      case 2:
        return <ExtensionIcon color='secondary' />;
      case 3:
        return <SupervisedUserCircleIcon color='secondary' />;
      case 4:
        return <ArchiveIcon color='secondary' />;
      case 5:
        return <AlternateEmailIcon color='secondary' />;
      default:
        return '';
    }
  };

  const openPage = (index: number) => {
    switch (index) {
      case 0:
        history.push(PATHS.ABOUT);
        break;
      case 1:
        history.push(PATHS.NEWS);
        break;
      case 2:
        history.push(PATHS.DEPARTMENTS);
        break;
      case 3:
        history.push(PATHS.EVENTS);
        break;
      case 4:
        history.push(PATHS.ARCHIVE);
        break;
      case 5:
        history.push(PATHS.CONTACT);
        break;
      default:
        break;
    }
  };

  return (
    <div className='header'>
      <div className='nav-bar'>
        <div className='logo-wrapper'>
          <a href={APP_PATHS.HOME} className='no-fading'>
            <img src={logo} alt='bkolologo' className='logo' />
          </a>
        </div>
        <div className='links-wrapper'>
          <a
            href={APP_PATHS.ABOUT}
            className={currentPath === APP_PATHS.ABOUT ? 'nav-elem nav-border selected-link' : 'nav-elem nav-border'}
          >
            O nama
          </a>
          <a href={APP_PATHS.NEWS} className={currentPath === APP_PATHS.NEWS ? 'nav-elem nav-border selected-link' : 'nav-elem nav-border'}>
            Aktualnosti
          </a>
          <a
            href={APP_PATHS.DEPARTMENTS}
            className={currentPath === APP_PATHS.DEPARTMENTS ? 'nav-elem nav-border selected-link' : 'nav-elem nav-border'}
          >
            Odjeli
          </a>
          <a
            href={APP_PATHS.EVENTS}
            className={currentPath === APP_PATHS.EVENTS ? 'nav-elem nav-border selected-link' : 'nav-elem nav-border'}
          >
            Manifestacije
          </a>
          <a
            href={APP_PATHS.ARCHIVE}
            className={currentPath === APP_PATHS.ARCHIVE ? 'nav-elem nav-border selected-link' : 'nav-elem nav-border'}
          >
            Arhiva
          </a>
          <a href={APP_PATHS.CONTACT} className={currentPath === APP_PATHS.CONTACT ? 'nav-elem selected-link' : 'nav-elem'}>
            Kontakt
          </a>
        </div>
        <div className='menu-button'>
          <IconButton color='inherit' aria-label='open drawer' edge='end' onClick={handleDrawerOpen} className={clsx(open && classes.hide)}>
            <MenuIcon />
          </IconButton>
        </div>
      </div>
      <div className='drawer-wrapper'>
        <Drawer
          className={classes.drawer}
          variant='persistent'
          anchor='right'
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>{theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}</IconButton>
          </div>
          <Divider />
          <List>
            {['O nama', 'Aktualnosti', 'Odjeli', 'Manifestacije', 'Arhiva', 'Kontakt'].map((text, index) => (
              <ListItem button key={text} onClick={() => openPage(index)}>
                <ListItemIcon>{getIcon(index)}</ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Drawer>
      </div>
    </div>
  );
};
export default Header;
