import React, { useState, useEffect } from 'react';
import Header from '../header';
import Footer from '../footer';
import leftImage from '../../images/cura.jpg';
import rightImage from '../../images/momak.jpg';
import Slider from '../news/slider';
import './home.css';
import ListElement from '../news/listelement';
import { News } from '../../interfaces/news';
import { NewsService } from '../../services';

const HomePage: React.FC = () => {
  const [news, setNews] = useState<News[]>([]);

  useEffect(() => {
    const getNews = async () => {
      const allNews = await NewsService.getAll();
      setNews(allNews);
    };

    getNews();
  }, []);

  return (
    <div className='app-div'>
      <Header />
      <Slider />
      <div className='main-page'>
        <div className='first-static-image'>
          <img width='350px' src={leftImage} alt='Error loading' />
        </div>
        <div className='news-wrapper'>
          <div className='title'>
            <p className='title-text'>Aktualnosti</p>
          </div>
          <div>
            <div className='center-div'>
              <div className='list-elements-wrapper'>
                {news.map((element) => (
                  <div className='list-element-wrapper'>
                    <ListElement id={element.id} title={element.name} content={element.description} date={element.createdAt} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className='second-static-image'>
          <img width='350px' src={rightImage} alt='Error loading' />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
