import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { ImageService } from '../../services';
import './imagedialog.scss';

interface ImageDialogProps {
  photos?: string[];
  index: number;
  setIndex: any;
  album?: number;
  open: boolean;
  handleClose: any;
  section: boolean;
}

const ImageDialogST: React.FC<ImageDialogProps> = ({ photos, index, setIndex, album, open, handleClose, section }) => {
  const back = () => {
    if (!photos) {
      return;
    }
    const newIndex = index - 1 > 0 ? index - 1 : photos.length - 1;
    setIndex(newIndex);
  };

  const forward = () => {
    if (!photos) {
      return;
    }
    const newIndex = index + 1 < photos.length ? index + 1 : 0;
    setIndex(newIndex);
  };

  const imgsrc = () => {
    let data = '';
    if (section && photos !== undefined) {
      data = ImageService.getSectionByFileName(album, photos[index]);
    } else if (!section && photos !== undefined) {
      data = ImageService.getTraditionByFileName(album, photos[index]);
    } else {
      data = '';
    }
    return data;
  };

  return (
    <Dialog maxWidth='lg' open={open} onClose={handleClose} aria-labelledby='responsive-dialog-title'>
      <DialogContent>
        <div className='back-button' onClick={handleClose}>
          <DoubleArrowIcon />
          Nazad
        </div>
        <div className='album-dialog'>
          <ArrowBackIosIcon className='buttons-album-image' onClick={() => back()} onKeyPress={() => back()} />
          <img src={imgsrc()} alt='Loading...' />
          <ArrowForwardIosIcon className='buttons-album-image' onClick={() => forward()} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ImageDialogST;
