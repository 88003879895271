import React, { useEffect, useState } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import { LinearProgress } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import Header from '../header';
import Footer from '../footer';
import leftImage from '../../images/cura.jpg';
import rightImage from '../../images/momak.jpg';
import './news.css';
import ListElement from './listelement';
import { News } from '../../interfaces/news';
import { NewsService } from '../../services';

const NewsPage: React.FC = () => {
  const [news, setNews] = useState<News[]>([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getNews = async () => {
      const allNews = await NewsService.getAll();
      setNews(allNews);
      setLoading(false);
    };

    getNews();
  }, []);

  return (
    <div className='app-div'>
      <Helmet>
        <title> Aktualnosti | HKC &quot;Bunjevačko kolo&quot; </title>
        <meta property='og:title' content='Aktualnosti | HKC "Bunjevačko Kolo"' />
        <meta property='og:url' content='http://hkcbunjevackokolo.rs/aktualnosti' />
      </Helmet>
      {loading && <LinearProgress color='secondary' />}
      <Header />
      <div className='main-page'>
        <div className='first-static-image'>
          <img width='350px' src={leftImage} alt='Error loading' />
        </div>
        <div className='news-wrapper'>
          <div className='title'>
            <p className='title-text'>Aktualnosti</p>
          </div>
          <div>
            <div className='center-div'>
              <div className='list-elements-wrapper'>
                {news.slice(page * 10 - 10, page * 10).map((element) => (
                  <div className='list-element-wrapper'>
                    <ListElement id={element.id} title={element.name} content={element.description} date={element.createdAt} />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Pagination count={Math.ceil(news.length / 10)} shape='rounded' size='large' onChange={(event, page) => setPage(page)} />
        </div>
        <div className='second-static-image'>
          <img width='350px' src={rightImage} alt='Error loading' />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NewsPage;
