import Http from './http';
import config from '../config';
import { News } from '../interfaces/news';

interface NewsService {
  getAll(): Promise<News[]>;
  getById(id: number): Promise<News>;
  getImage(id: number): string;
}

class NewsServiceImpl implements NewsService {
  getAll = (): Promise<News[]> => Http.get(`${config.API_URLS.news}`);

  getById = (id: number): Promise<News> => Http.get(`${config.API_URLS.news}/${id}`);

  getImage = (id: number | undefined): string => `${config.BASE_URL}/${config.API_URLS.albums}/news${id}/1.jpg`;
}

export default new NewsServiceImpl();
