import Http from './http';
import config from '../config';
import { AlbumModel } from '../interfaces/album';
import { AlbumImages } from '../interfaces/album-images';

interface ImageService {
  getAll(): Promise<AlbumModel[]>;
  getAlbumImages(idFolder: number): Promise<AlbumImages>;
  getAPIAddress(folder: number, fileName: string): string;
  getSectionByFileName(idsection: string, filename: string): string;
  getTraditionByFileName(idtradition: string, filename: string): string;
}

class ImageServiceImpl implements ImageService {
  getAll = (): Promise<AlbumModel[]> => Http.get(config.API_URLS.albums);

  getAlbumImages = (folderName): Promise<AlbumImages> => Http.get(`${config.API_URLS.albums}/${folderName}`);

  getAPIAddress = (folder, fileName): string => `${config.BASE_URL}/${config.API_URLS.albums}/${folder}/${fileName}`;

  getSectionByFileName = (idsection, filename) => `${config.BASE_URL}/${config.API_URLS.imgsections}/${idsection}/${filename}`;

  getTraditionByFileName = (idtradion, filename) => `${config.BASE_URL}/${config.API_URLS.imgtraditions}/${idtradion}/${filename}`;
}

export default new ImageServiceImpl();
