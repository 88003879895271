import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import Header from '../../header';
import Footer from '../../footer';
import './about-detailes.scss';
import imgPovijest from '../../../images/povijest.jpg';
import imgVodstvo from '../../../images/vodstvo.jpg';

const AboutDetailsPage: React.FC = () => {
  const [content, setContent] = useState<any>('');
  const [title, setTitle] = useState('');
  const { type } = useParams<{ type: string }>();
  const [img, setImg] = useState('');

  useEffect(() => {
    if (type === 'povijest') {
      setContent(
        <span>
          Osnovano na veoma posjećenoj skupštini 18. siječnja 1970., HKUD Bunjevačko kolo je opravdalo svoje postojanje već u prvim
          mjesecima svoga djelovanja. Kao nijedno društvo prije, okupilo je gotovo sve viđenije hrvatske intelektualce različitih profila,
          ideoloških orijentacija i političke prošlosti. Kao takvo, moglo je prirediti više manifestacija i priredaba, koje po značenju nisu
          zaostajale za onim koje je upriličio Organizacijski odbor za Dužijancu. Međutim, točan slijed aktivnosti HKUD-a Bunjevačko kolo u
          početnom razdoblju njegovog djelovanja teško je pratiti, uslijed raspršenosti i fragmentiranosti relevantnih izvora. Sâma arhiva
          Bunjevačkog kola oskudijeva dokumentima iz razdoblja 1970. – 1972. Dokumenti o radu pojedinih sekcija Društva (istraživačka i
          književna sekcija) nalaze se u zaostavštini Bele Gabrića (Bunjevačko-šokačka knjižnica Ivan Kujundžić). Ovakvo stanje je
          posljedica prije svega neprilika koje su 1973. snašle HKUD Bunjevačko kolo i uostalom čitavu hrvatsku intelektualnu elitu.
          <br />
          Okvirnu predstavu o djelovanju HKUD-a Bunjevačko kolo od 1970. do 1972. pruža tisak. Kako je to ocijenio suvremenik i sudionik
          događaja Naco Zelić u svojoj knjizi Hrvatsko proljeće i bački Hrvati (2009.), „o osnivanju i radu Hrvatskoga kulturno-umjetničkoga
          društva Bunjevačko kolo u Subotici, (...) o priredbama Dužijance, o Velikom prelu, o otkrivanju spomen biste Ambrozija Boze
          Šarčevića i o drugim priredbama bačkih Hrvata sve do kraja 1971. godine izvještaji u lokalnim tiskovinama (Subotičke novine),
          ostalim glasilima (Politika, Borba, Vjesnik, Magyar Szó, 7 Nap), te radijskim i televizijskim emisijama, pretežito su
          afirmativni&quot;.
          <br />
          <br /> <h4>Osam sekcija</h4>
          <br /> Novoosnovano Društvo je dobilo kvalitetno vodstvo i kadrove, koji su bili jamstvo njegovog plodonosnog rada. Za
          predsjednika Društva je izabran Ivo Stantić (sudac Okružnog privrednog suda u Subotici), za dopredsjednike Josip Buljovčić
          (profesor Više pedagoške škole u Subotici) i diplomirani pravnik Pajo Pavluković, za tajnike Naco Zelić, Milivoj Prćić i Grgo
          Bačlija, ekonoma Ante Pokornik, za blagajnicu Justina Francišković. Spomenuti su zajedno s još 46 članova činili Upravni odbor.
          Ubrzo nakon osnivanja, Bunjevačko kolo je razgranalo svoju djelatnost kroz rad osam sekcija. U pitanju su sekcija za Dužijancu na
          čelu s predsjednikom Markom Peićem (dopredsjednikom Općine Subotica), sekcija za znanstveno-istraživački rad na čelu s Markom
          Horvackim (sucem Ustavnog suda SAP Vojvodine), sekcija Pučke kasine na čelu s Grgom Prćićem (sucem Okružnog privrednog suda u
          Subotici), likovna sekcija na čelu s Ivanom Tikvickim Pudarom (sucem Okružnog suda u Subotici), muzička sekcija na čelu s Pavlom
          Bačićem (profesorom Muzičke škole u Subotici), folklornom sekcijom na čelu s Kalom Margetićem (nastavnikom u OŠ Matko Vuković),
          sekcija za organizaciju i ustrojstvo članstva i društva na čelu s Jakovom Kujundžićem (sucem Općinskog suda u Subotici) i dramska
          sekcija na čelu s Blaškom Ivićem. Po učinkovitosti istaknula se u prvom redu sekcija za Dužijancu, folklorna i
          znanstveno-istraživačka sekcija. <br />
          <br />
          <h4>Uspješna prva godina</h4>
          <br /> Već prve godine svoga rada Bunjevačko kolo je organiziralo nekoliko manifestacija, koje su okupile veliki broj ljudi. U
          prvom redu to su: Dužijanca 1970., zatim Veliko prelo, koje je 30. siječnja 1971. održano u Sajamsko-sportskoj dvorani u Subotici
          u nazočnosti oko 2.000 posjetitetlja uz bogat folklorni i glazbeni program, svečana akademija u povodu proslave 100. godišnjice
          Bunjevačkih i šokačkih novina, koja je održana u velikoj dvorani subotičkog Narodnog kazališta 14. ožujka 1971. Nakon uspješne
          godine, Bunjevačko kolo je održalo 21. ožujka 1971. Godišnju skupštinu u Velikoj vijećnici Gradske kuće u Subotici, na kojoj su,
          osim članova, sudjelovali i gosti iz Hrvatske Mladen Iveković i Miroslav Vaupotić, predsjednik i dopredsjednik Matice hrvatske. I
          na polju kulture sjećanja Bunjevačko kolo je zabilježilo vrijedne rezultate. Sudjelovalo je u otkrivanju poprsja hrvatskog
          preporoditelja Ambrozija Boze Šarčevića u Nazorovoj ulici u Subotici 4. travnja 1971. Poprsje je na zahtjev Jose Šok čića izradio
          još sredinom pedesetih godina Ivan Meštrović, slavno ime hrvatskog kiparstva. Nastupi u Hrvatskoj Bunjevačko kolo je sa svojom
          folklornom sekcijom nastupalo na više manifestacija po Vojvodini i Hrvatskoj, od kojih naročito vrijedi izdvojiti proslavu 100.
          godišnjice rođenja hrvatskog narodnog vođe Stjepana Radića u Đakovu 12. i 13. lipnja (na čelu s predsjednikom Ivanom Stantićem i
          tajnikom Markom Horvackim), na Večeri bunjevačkih Hrvata u okviru svečanosti U svijetu bajke Ivane Brlić Mažuranić u Slavonskom
          Brodu 9. listopada 1971., te na Đakovačkim vezovima i Vinkovačkim jesenima iste godine. Naročiti dojam na hrvatsku matičnu publiku
          ostavio je spomenuti nastup Bunjevačkog kola u Slavonskom Brodu. &quot;Pedeset i pet izvođača, mladića i djevojaka Hrvata iz
          Subotice, osjetilo je te večeri kako su dobrodošli u &quot;hrvatsku Slavoniju&quot;, kako reče predsjednik društva. I neka ponovno
          dođu. Oni žele da ojača lanac kulturnih veza koji će ih vezati s ognjištem djedova i otaca. Neka ih zove i Brod, Đakovo, Požega,
          Vinkovci, Našice, neka ih zovu odasvud – ići će svakamo gdje su ih spremni primiti, jer je srce bunjevačkih Hrvata tako
          široko&quot;, piše Hrvatski tjednik od 29. listopada 1971. Istraživačka sekcija U ostvarivanju gore iznesenih rezultata naročito
          se istaknula istraživačka sekcija. Prvi sastanak istraživačke sekcije održan je 28. veljače 1970. u prostorijama Gradske
          knjižnice. U uvodnoj riječi rukovodilac sekcije Marko Horvacki iznio je prijedlog »da se osvijetle sve komponente života
          Hrvata-Bunjevaca ovih krajeva, od kulturno-povijesnih do privrednih i etnografskih«. Na ovom sastanku je iznesen prijedlog o
          pokretanju godišnjaka, koji bi donosio radnje iz područja povijesti i kulturne baštine Hrvata-Bunjevaca. (Tek 50 godina kasnije
          bit će realizirana ideja o pokretanju godišnjaka, ali u izdanju Zavoda za kulturu vojvođanskih Hrvata.) Drugi sastanak
          istraživačke sekcije održan je 10. travnja 1970. u prostorijama Gradske knjižnice, u sastavu Marko Horvacki, Bela Gabrić, Albe
          Rudinski, Ive Prćić, Geza Kikić, Naco Zelić, Barnaba Mandić i Ivan Prćić Gospodar. Na njemu je usvojen jedinstven stav glede
          novoobjavljene knjige Albe M. Kuntića Počeci borbe za preporod bačkih Bunjevaca (1969.), koja je pisana s ciljem nijekanja
          povijesnog značaja preporodnog pokreta Ivana Antunovića. Bunjevačko kolo se uključilo i u književne tokove. Kada je
          Kulturno-prosvjetna zajednica Srbije raspisala natječaj za dodjelu Vukove nagrade, Upravni odbor Bunjevačkog kola je uz suglasnost
          Književne sekcije predložio Balinta Vujkova za spomenutu nagradu (listopada 1970.). Vujkov će na koncu dobiti spomenutu nagradu
          1971. U jesen 1970. članovi Bunjevačkog kola (Bela Gabrić, Matija Poljaković) su u suradnji sa Subotičkom biskupijom pokrenuli
          kalendar Danicu za 1971. godinu. Ubrzo po izlasku Danice iz tiska, oglasila su se i komunistička glasila (Subotičke novine, NIN,
          Borba itd.). Ocijenivši kalendar »antinarodnim«, partijski novinari predložili su vlastima obračun s izdavačima i piscima
          kalendara. To je bio prvi nagovještaj pogroma, koji će uslijediti poslije 21. sjednice Predsjedništva Saveza komunista Jugoslavije
          u Karađorđevu koncem 1971. Tijekom 1971. izašlo je nekoliko značajnih publikacija članova Bunjevačkog kola u Subotici i Zagrebu:
          Cvjetovi mećave: hrvatske narodne pripovijetke iz Mađarske, Rumunjske, Austrije i Čehoslovačke Balinta Vujkova, Antologija proze
          bunjevačkih Hrvata i Antologija poezije bunjevačkih Hrvata Geze Kikića u izdanju Matice hrvatske (Zagreb), te Bunjevačke narodne
          pisme Ive Prćića u izdanju Osvita (Subotica), prva knjiga Vojislava Sekelja Djetinjstvo. Na koncu, vrijedi primijetiti da
          aktivnosti koje je HKUD Bunjevačko kolo izvelo u razdoblju 1970. – 1972. predstavljaju samo neznatni dio planiranih aktivnosti. Iz
          dokumenata Upravnog odbora i pojedinih sekcija vidi se da rad Društva nije bio usmjeren samo na folklorne, tradicionalne,
          turističke, prigodne i zabavne svečanosti nego i na ostvarivanje trajnih rezultata na polju književnosti i znanosti. Izvor:
          Hrvatska riječ (Vladimir Nimčević)
        </span>,
      );
      setTitle('Povijest');
      setImg(imgPovijest);
    } else {
      setContent(
        <span>
          <p className='text-uo'>
            Upravni odbor (UO) je sačinjen od 11 članova koje bira skupština Centra i mandat im traje četiri godine.
            <br />
            <br />
            Predsjednik UO:
            <br />
            Lazar Cvijin
            <br />
            <br />
            Članovi:
            <br />
            Pavle Kujundžić
            <br />
            Denis Lipozenčić
            <br />
            Dajana Šimić
            <br />
            Tanja Dulić
            <br />
            Željka Vukov
            <br />
            Ivica Tumbas
            <br />
            Marinko Katić
            <br />
            Antun Kozma
            <br />
            Svetislav Milanković
            <br />
            Ivica Ivanković Radak
            <br />
            <br />
            Izvršni odbor:
            <br />
            Predsjednik IO:
            <br />
            Marin Piuković
            <br />
            <br />
            Članovi:
            <br />
            Marin Jaramazović
            <br />
            Senka Horvat
            <br />
            Vlatko Vidaković
            <br />
            Josipa Križanović
            <br />
            Nevena Mlinko
            <br />
            Marjan Kiš
            <br />
            <br />
            Nadzorni odbor:
            <br />
            <br />
            Predsjednik NO:
            <br />
            Miroslav Doko
            <br />
            <br />
            Članovi:
            <br />
            Mirko Ostrogonac
            <br />
            Suzana Vukov
            <br />
          </p>
        </span>,
      );
      setTitle('Uprava');
      setImg(imgVodstvo);
    }
  }, []);

  return (
    <div className='app-div'>
      <Header />
      <a className='back-button' href='/o_nama'>
        <DoubleArrowIcon />
        Nazad
      </a>
      <div>
        <div className='dep-container'>
          <p className='dep-title'>{title}</p>
          <p className='dep-content'>{content}</p>
          <div className='images-container'>
            <img src={img} alt='Error loading' />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutDetailsPage;
