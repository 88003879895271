import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Helmet } from 'react-helmet';
import Header from '../header';
import Footer from '../footer';
import './departments.scss';
import Tile from '../tile';
import TileContent from '../tileContent';
import { Section } from '../../interfaces/section';
import { ImageService, SectionService } from '../../services';
import imageService from '../../services/image.service';

const DepartmentsPage: React.FC = () => {
  const [departments, setDepartments] = useState<Section[]>([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const showDepartment = (id: number) => {
    history.push(`odjel/${id}`);
  };

  useEffect(() => {
    const getDepartments = async () => {
      const allDepartments = await SectionService.getAll();
      setDepartments(allDepartments);
      setLoading(false);
    };
    getDepartments();
  }, []);

  return (
    <div className='app-div'>
      <Helmet>
        <title> Odjeli | HKC &quot;Bunjevačko kolo&quot; </title>
        <meta property='og:title' content='Odjeli' />
        <meta property='og:url' content='http://hkcbunjevackokolo.rs/odjeli' />
        <meta property='og:image' content={`${ImageService.getSectionByFileName(1, '1.jpg')}`} />
      </Helmet>
      {loading && <LinearProgress color='secondary' />}
      <Header />
      <div className='departments-wrapper'>
        {departments.map((department, index) => (
          <div className='department-wrapper' onClick={() => showDepartment(department.id)}>
            {index % 2 === 0 ? (
              <Tile
                leftElement={<TileContent title={department.name} text={department.description} />}
                rightElement={
                  <img
                    className='department-image'
                    width='100%'
                    src={imageService.getSectionByFileName(department.id, department.inputImage)}
                    alt='Error loading'
                  />
                }
              />
            ) : (
              <Tile
                rightElement={<TileContent title={department.name} text={department.description} />}
                leftElement={
                  <img
                    className='department-image'
                    width='100%'
                    src={imageService.getSectionByFileName(department.id, department.inputImage)}
                    alt='Error loading'
                  />
                }
              />
            )}
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default DepartmentsPage;
